import React, { useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';
import { HEADER_BUTTONS } from 'const/SalaryHeader';
import { ROUTES } from 'const';
import { SALARY_HEADER_LABELS } from 'const/translations';
import { Button } from 'components/ui';
import { FRENCH_ROUTES } from 'const/translations/Routes';
import { setModalVisibility } from 'modules/SalaryModule/SalaryModal/actions';
import { usePathOverlapWith } from 'hooks/usePathOverlapWith';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import HeaderStyles from '../SalaryHeader/SalaryHeader.module.css';
import ButtonStyles from './SendInfoButton.module.css';

const { SEND_INFO } = HEADER_BUTTONS;

const {
  SALARIES_REWARDS,
  SALARIES_CALENDAR,
  SALARIES_MAIN_PAGE,
  EMPLOYEE_REGISTER_TITLE,
  EMPLOYEE_REGISTER_FORM,
  SALARIES_COMPANY_SETTINGS,
  SALARIES_EMPLOYEES,
} = ROUTES;
const {
  SALARIES_REWARDS: FR_SALARIES_REWARDS,
  SALARIES_CALENDAR: FR_SALARIES_CALENDAR,
} = FRENCH_ROUTES;

const propTypes = {
  className: PropTypes.string,
  cancelHide: PropTypes.bool,
};

export const SendInfoButton = memo(({ className, cancelHide = false }) => {
  const dispatch = useDispatch();

  const handleShowSendInfoModal = useCallback(() => {
    dispatch(setModalVisibility(true));
  }, [dispatch]);

  const isButtonHidden = usePathOverlapWith(
    [
      FR_SALARIES_REWARDS,
      FR_SALARIES_CALENDAR,
      SALARIES_REWARDS,
      SALARIES_CALENDAR,
      SALARIES_MAIN_PAGE,
      EMPLOYEE_REGISTER_TITLE,
      EMPLOYEE_REGISTER_FORM,
      SALARIES_COMPANY_SETTINGS,
      SALARIES_EMPLOYEES,
    ],
    true
  );

  if (!cancelHide && isButtonHidden) {
    return null;
  }

  return (
    <Button
      className={clsx(ButtonStyles.button, HeaderStyles.btn, className)}
      sizeAuto
      big
      variant="secondary"
      name={SEND_INFO}
      onClick={handleShowSendInfoModal}
    >
      {SALARY_HEADER_LABELS.SEND_INFO}
    </Button>
  );
});

SendInfoButton.propTypes = propTypes;
