import React from 'react';
import PropTypes from 'prop-types';
import Styles from 'components/UploadDocuments/FileUploadManager/UploadManagerDesktopLayout/UploadManagerDesctopLayout.module.css';
import { Typography, Upload } from 'antd';
import iconUpload from 'assets/icons/icon-upload.svg';
import { FileListWithTotal } from 'components/UploadDocuments/FileListWithTotal/FileListWithTotal';
import clsx from 'clsx';
import iconMail from 'assets/icons/mail.svg';
import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';

const { Dragger } = Upload;

const {
  DROPDOWN_AREA: { TITLE, DESCRIPTION_10MO, DESCRIPTION_25MO },
  FILES_LIST: { EMPTY },
  BUTTON_CAPTION,
  PRECOMPTA_EMAIL_TEXT,
} = UPLOAD_DOCUMENT_TRANSLATIONS;

const propTypes = {
  uploadedFiles: PropTypes.array,
  beforeUpload: PropTypes.func,
  handleChange: PropTypes.func,
  handleRemove: PropTypes.func,
  handleReset: PropTypes.func,
  submitted: PropTypes.bool,
  initiateUpload: PropTypes.func,
  listHasValidFiles: PropTypes.bool,
  isPreCompta: PropTypes.bool,
  isPreComptaMailCollector: PropTypes.bool,
  preComptaMail: PropTypes.string,
  isRenameable: PropTypes.bool,
  setIsOneFileEditing: PropTypes.func,
};

export const UploadManagerDesktopLayout = ({
  uploadedFiles,
  beforeUpload,
  handleChange,
  handleRemove,
  handleReset,
  submitted,
  initiateUpload,
  listHasValidFiles,
  isPreCompta,
  isPreComptaMailCollector,
  preComptaMail,
  isRenameable,
  setIsOneFileEditing,
}) => (
  <>
    <div className={Styles.dropzoneWrapper}>
      <div className={Styles.dropzone__area}>
        <Dragger
          name="uploader"
          showUploadList={false}
          multiple
          fileList={uploadedFiles}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          className={Styles.dragger}
        >
          <div>
            <div className={Styles.iconUpload}>
              <img src={iconUpload} alt="icon_upload" />
            </div>
            <div>
              <Typography.Text className={Styles.dropzoneTitle}>
                {TITLE}
              </Typography.Text>
            </div>
            <div className={Styles.dropzoneDesc}>
              {isPreCompta ? DESCRIPTION_25MO : DESCRIPTION_10MO}
            </div>
          </div>
        </Dragger>
      </div>
      <div className={Styles.dropzone__uploadList}>
        {uploadedFiles.length ? (
          <FileListWithTotal
            files={uploadedFiles}
            onRemove={handleRemove}
            onReset={handleReset}
            isRenameable={isRenameable}
            handleChange={handleChange}
            setIsOneFileEditing={setIsOneFileEditing}
          />
        ) : (
          <div className={Styles.emptyList}>
            <Typography.Text className={Styles.emptyListText}>
              {EMPTY}
            </Typography.Text>
          </div>
        )}
      </div>
      {isPreCompta && isPreComptaMailCollector && preComptaMail && (
        <div className={Styles.emailText}>
          {PRECOMPTA_EMAIL_TEXT}{' '}
          <a
            href={`mailto:${preComptaMail}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {preComptaMail}
          </a>
        </div>
      )}
    </div>

    <div className={Styles.dropzone__upload}>
      <button
        type="button"
        className={clsx(Styles.dropzone__uploadButton, {
          [Styles.disabled]:
            !uploadedFiles.length || !listHasValidFiles || submitted,
        })}
        disabled={!uploadedFiles.length || !listHasValidFiles || submitted}
        onClick={initiateUpload}
      >
        <img
          className={Styles.dropzone__uploadButtonIcon}
          src={iconMail}
          alt=""
        />
        <span className={Styles.buttonText}>{BUTTON_CAPTION}</span>
      </button>
    </div>
  </>
);

UploadManagerDesktopLayout.propTypes = propTypes;
