import { request } from 'utils/request';

export const getRegisteredCompaniesInfos = () =>
  request.get('/me').then(({ data }) => data);

export const setNewPrimaryCompany = (id) =>
  request.post('/client/change-company', { id });

export const getCompanyData = (id) => request.get(`/companies/${id}/detailed`);

export const setCurrentCompanyApiKey = ({ companyId, apiKey }) =>
  request.post(`/companies/${companyId}`, { apiKey });

export const fetchIsanetRedirectUrl = () => request.get('/isanet-factomos-sso');

export const getCompaniesData = (search) =>
  request.get('/companies', { params: { limit: 100, search } });

export const getCompanyDashboardData = () => request.get('/dashboards/company');

export const getUserFinancials = (exercice, transition = false, companyId) => {
  let query = `?exercice=${exercice}&`;
  query += `transition=${transition}`;
  return request.get(`/dashboards/${companyId}/financial-preview${query}`);
};

export const acceptTermsOfUseService = () => request.get('/me/accept-terms');
