import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';
import clsx from 'clsx';
import { UPLOAD_DOCUMENTS_FILE_ERRORS } from 'const';
import { FileList } from '../FileList/FileList';
import Styles from './FileListWithTotal.module.css';

const {
  FILES_LIST: { TOTAL_SINGLE, TOTAL_MULTIPLE, REMOVE_ALL },
} = UPLOAD_DOCUMENT_TRANSLATIONS;
const {
  COUNT_LIMIT_ERROR,
  SIZE_LIMIT_ERROR,
  TYPE_FORBIDDEN_ERROR,
} = UPLOAD_DOCUMENTS_FILE_ERRORS;

const FileListWithTotal = ({
  files,
  onRemove,
  onReset,
  isMobileView,
  isRenameable,
  handleChange,
  setIsOneFileEditing,
}) => (
  <div className={Styles.wrapper}>
    <FileList
      files={files}
      onRemove={onRemove}
      isRenameable={isRenameable}
      handleChange={handleChange}
      setIsOneFileEditing={setIsOneFileEditing}
    />
    <div className={clsx(Styles.info, isMobileView ? '' : Styles.row)}>
      <Typography.Text className={Styles.totalText}>
        {files.length === 1
          ? TOTAL_SINGLE
          : TOTAL_MULTIPLE.replace('{{AMOUNT}}', files.length)}
      </Typography.Text>
      <div onClick={onReset}>
        <span className={Styles.removeAll}>{REMOVE_ALL}</span>
      </div>
    </div>
  </div>
);

FileListWithTotal.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      name: PropTypes.string,
      errors: PropTypes.shape({
        [COUNT_LIMIT_ERROR]: PropTypes.bool,
        [SIZE_LIMIT_ERROR]: PropTypes.bool,
        [TYPE_FORBIDDEN_ERROR]: PropTypes.bool,
      }),
      onRemove: PropTypes.func,
    })
  ),
  onRemove: PropTypes.func,
  onReset: PropTypes.func,
  isMobileView: PropTypes.bool,
  isRenameable: PropTypes.bool,
  handleChange: PropTypes.func,
  setIsOneFileEditing: PropTypes.func,
};

const MemoFileListWithTotal = memo(FileListWithTotal);

export { MemoFileListWithTotal as FileListWithTotal };
