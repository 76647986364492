export const FOOTER = {
  EXCO_SIGN: 'EXCO \u00a9 {{YEAR}}',
};

export const CURRENT_YEAR = new Date().getFullYear();

export const ABSENCES_FILTER_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_DOTTED = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT_WITHOUT_SECONDS = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const SHORT_MONTH_YEAR_FORMAT = 'MM/YYYY';
export const YEAR_FORMAT = 'YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export const STATUS_NOT_REQUESTED = 'NOT_REQUESTED';
export const STATUS_LOADING = 'LOADING';
export const STATUS_SUCCESS = 'SUCCESS';
export const STATUS_ERROR = 'ERROR';

export const SHAREPOINT_FORBIDDEN_ERROR_MESSAGE = 'SHAREPOINT ACCESS DENIED';

export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  UNSUBSCRIBE: '/unsubscribe',

  MY_EXCO_LANDING: '/my-exco/landing',
  EXPENSES_LANDING: '/expenses/landing',
  SALES_LANDING: '/sales/landing',
  WAIBI_LANDING: '/advanced-indicators/landing',
  TERMS_OF_USE_LANDING: '/terms-of-use/landing',
  TERMS_OF_SUBSCRIPTION: '/terms-of-subscription/landing',

  HELP: '/help',
  EXPENSES: '/expenses',
  DEBTORS: '/debtors',
  DEBTOR: '/debtors/:code',
  CREDITORS: '/creditors',
  CREDITOR: '/creditors/:code',
  EMPLOYEE_REGISTER_TITLE: '/employee-register',
  EMPLOYEE_REGISTER_FORM: '/employee-register/new',
  UPLOAD_DOCUMENTS: '/upload-documents',
  INVOICE_SEARCH: '/invoice-search',
  DOCUMENTS_CABINET: '/documents-cabinet',
  NOT_AUTHORIZED: '/not-authorized',
  CHANGE_PASSWORD: '/change-password',
  TEMPORARY_CHANGE_PASSWORD: '/temporary-change-password',
  FORGOT_PASSWORD: '/forgot-password',
  PASSWORD_SENT: '/password-sent',
  PASSWORD_CHANGED: '/password-changed',
  CONTACTS: '/contacts',
  MY_INFORMATION: '/my-information',
  TURNOVER: '/turnover',
  TREASURY: '/treasuryPage',
  HR: '/human-resources',
  STORE: '/store',
  CART: '/cart',
  ADDITIONAL_USERS: '/additional-users',
  NEWS: '/news',

  // Salaries module
  SALARIES_MAIN_PAGE: '/salaries',
  SALARIES_EMPLOYEES: '/salaries/employees',
  SALARIES_REWARDS: '/salaries/rewards',
  SALARIES_CALENDAR: '/salaries/calendar',
  SALARIES_COMPANY_SETTINGS: '/salaries/company-settings',
  SALARIES_SCHEDULE_EMPTY: '/salaries/company-settings/schedule/',
  SALARIES_COMPANY_SETTINGS_SCHEDULE:
    '/salaries/company-settings/schedule/:id?',
  SALARIES_COMPANY_SETTINGS_REWARD_CREATE: '/salaries/company-settings/rewards',
  SALARIES_COMPANY_SETTINGS_REWARD: '/salaries/company-settings/rewards/:id?',
};

export const EXTERNAL_ROUTES = {
  EXCO: 'https://exco.fr/',
  TWITTER: 'https://twitter.com/exco_france',
  LINKEDIN: 'https://www.linkedin.com/company/exco/mycompany/',
  YOUTUBE: 'https://www.youtube.com/channel/UCdLEx0TboPWBR7W-R5KDElg',
  EXCO_AGENCIES: 'https://exco.fr/cabinets/exco-fiduciaire-du-sud-ouest/',
  EXCO_CONTACT:
    'https://exco.fr/cabinets/exco-fiduciaire-du-sud-ouest/#contact',
  WAIBI_LOGIN: 'https://my-exco-fso.waibi.app/login#waibi_at=',
  QUESTIONS: 'https://aide.myexco.net/faq/fr-fr',
};

export const APP_MODULES = {
  EVP: 'EVP',
  SEARCH_INVOICES: 'SEARCH_INVOICES',
  UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
  DOCUMENTS_CABINET: 'BROWSE_SHAREPOINT',
  APPROVE_INVOICE: 'APPROVE_INVOICE',
  PRE_VALIDATE_INVOICE: 'PRE_VALIDATE_INVOICE',
  READONLY_INVOICE: 'READONLY_INVOICE',
  ISA_COLLAB: 'ISA_COLLAB',
  PAY_INVOICE: 'PAY_INVOICE',
  APPROVE_AND_PAY_INVOICE: 'APPROVE_AND_PAY_INVOICE',
  ISANETFACT: 'ISANETFACT',
  WAIBI: 'WAIBI_ADVANCED_INDICATORS',
  DASHBOARD_FIN: 'DASHBOARD_FIN',
  DASHBOARD_HR: 'DASHBOARD_HR',
  DASHBOARD_DETAILS: 'DASHBOARD_DETAILS',
  ADDITIONAL_USER: 'ADDITIONAL_USER',
  PAY_MANAGER: 'PAY_MANAGER',
  ACCESS_SOCIAL_DIR: 'ACCESS_SOCIAL_DIR',
};

export const EXCEL_FILES_EXTENSIONS = ['xls', 'xlsx'];
export const WORD_FILES_EXTENSIONS = ['doc', 'docx'];
export const POWERPOINT_FILES_EXTENSIONS = ['ppt', 'pptx'];
export const PDF_FILES_EXTENSION = 'pdf';
export const MAX_FILES_SIZE_25MO = 26214400; // 26214400 bytes = 25 MiB = 25 Mo;
export const MAX_FILES_SIZE_10MO = 10485760; // 10485760 bytes = 10 MiB = 10 Mo

export const INVOICE_SEARCH_TABLE_SIZE_OPTIONS = ['10', '30', '50', '100'];
export const INVOICE_SEARCH_PAYMENT_STATUSES = {
  ALL: 'all',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  IN_PROGRESS: 'inProgress',
  READY_FOR_PAYMENT: 'readyForPayment',
  NOT_VALIDATED: 'notValidated',

  /* Not actually payment statuses!
  Keys below are used as paymentStatus values
  only on frontend and getting filtered out
  on requests to backend */

  DELETED: 'deleted',
  PAID_OUTSIDE: 'paidOutside',
  PREVALIDATED: 'prevalidated',
};
export const INVOICE_TYPE_PURCHASE = 'Achat';
/**
 * Duration must be the the same on backend
 */
export const INVOICE_POLLING_DURATION = 60000;

export const SCREEN_WIDTH_BREAKPOINT_VALUE = 600;
export const SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

export const UPLOAD_DOCUMENTS_FILE_ERRORS = {
  COUNT_LIMIT_ERROR: 'countLimitError',
  SIZE_LIMIT_ERROR: 'sizeLimitError',
  TYPE_FORBIDDEN_ERROR: 'typeForbiddenError',
  FILE_PATTERN_ERROR: 'filePatternError',
  FILE_NAME_REPEATS: 'fileNameRepeats',
  FILE_NAME_REQUIRED: 'fileNameRequired',
};

export const REGEX_ENCODED_PERCENT_ALL_INSTANCES = /%25/g;

export const EMPTY_FUNCTION = () => {};
export const EMPTY_COMPARATOR = () => 0;

export const EMPTY_BASE64 = 'data:';

export const EURO_SIGN = '€';

export const NEO_EXPERT_EXTENSIONS = [
  'pdf',
  'tiff',
  'jpeg',
  'jpg',
  'bmp',
  'png',
];
