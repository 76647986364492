import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu, Select } from 'antd';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import MainStyles from 'assets/styles/main.module.css';
import { ROUTES, STATUS_SUCCESS } from 'const';
import { NOTIFICATION_TEXT_TRANSLATIONS } from 'const/translations';
import { Notification } from 'components/Notification';
import { MobileBlocker } from 'components/MobileBlocker';
import { CartDropdown } from 'components/CartDropdown/CartDropdown';
import { getScreenWidthBreakpointMedia } from 'utils';
import { useSimpleMedia } from 'hooks/useMedia';
import { Logo } from 'components/ui/Logo';
import { getCompaniesRequest } from 'modules/loggedUserInfo/actions';

import HeaderStyles from './MainHeader.module.css';
import { HamburgerButton } from '../ui/HamburgerButton';
import { PROP_TYPE_STATUS } from '../../const/propTypes';
import { LogoutDropdown } from './LogoutDropdown';
import { ModalContainer } from '../../containers/ModalContainer';

const { MOBILE_DISCLAIMER } = NOTIFICATION_TEXT_TRANSLATIONS;

const { Option } = Select;

const propTypes = {
  registeredCompanies: PropTypes.array,
  currentCompany: PropTypes.object,
  handleCompanyChange: PropTypes.func,
  isSideBarOpen: PropTypes.bool,
  isSideBarOpenToggle: PropTypes.func,
  userInfoStatus: PROP_TYPE_STATUS,
  userInfoFullName: PropTypes.string,
  modalProps: PropTypes.object,
  isMainUser: PropTypes.bool,
};

const SCREEN_WIDTH_BREAKPOINT_MEDIA = getScreenWidthBreakpointMedia(1300);

const MainHeader = React.memo(
  ({
    registeredCompanies,
    currentCompany,
    handleCompanyChange,
    isSideBarOpen,
    isSideBarOpenToggle,
    userInfoStatus,
    userInfoFullName,
    modalProps,
    isMainUser,
  }) => {
    const dispatch = useDispatch();
    const isLaptopVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);

    const onSelectSearch = useCallback(
      (value) => {
        const payload = value ? { search: value } : {};
        dispatch(getCompaniesRequest(payload));
      },
      [dispatch]
    );

    return (
      <header className={MainStyles.header}>
        <ModalContainer {...modalProps} />
        <Menu
          mode="horizontal"
          role="navigation"
          className={clsx(MainStyles.headerMenu, MainStyles.container)}
        >
          {isLaptopVersion && (
            <HamburgerButton
              isOpen={isSideBarOpen}
              toggleIsOpen={isSideBarOpenToggle}
            />
          )}
          <Menu.Item key={ROUTES.ROOT} className={HeaderStyles.menuItem}>
            <Logo className={HeaderStyles.mainLogo} />
          </Menu.Item>
          <Menu.Item
            className={clsx(HeaderStyles.menuItem, MainStyles.companySelect)}
          >
            <Select
              value={currentCompany.id}
              className={HeaderStyles.coloredDropdown}
              onSelect={handleCompanyChange}
              dropdownClassName={HeaderStyles.dropdownMenu}
              onSearch={onSelectSearch}
              filterOption={false}
              showSearch
              getPopupContainer={() => document.getElementById('dropdown-area')}
            >
              {registeredCompanies
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ id, name }) => (
                  <Option value={id} className={HeaderStyles.option} key={id}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Menu.Item>
          {!!currentCompany.activated && isMainUser && (
            <Menu.Item
              key="cart"
              className={clsx(
                HeaderStyles.menuItem,
                HeaderStyles.profileDropdown,
                HeaderStyles.check
              )}
            >
              <CartDropdown />
            </Menu.Item>
          )}
          {userInfoStatus === STATUS_SUCCESS && (
            <Menu.Item
              key="logout"
              className={clsx(
                HeaderStyles.menuItem,
                HeaderStyles.profileDropdown,
                HeaderStyles.userMenu
              )}
            >
              <LogoutDropdown
                userInfoFullName={userInfoFullName}
                isCurrentCompanyActivated={!!currentCompany.activated}
                isMainUser={isMainUser}
              />
            </Menu.Item>
          )}
        </Menu>
        <MobileBlocker
          mobile={
            <Notification
              message={MOBILE_DISCLAIMER}
              id="mobile-disclaimer"
              headerFix
            />
          }
          desktop={null}
        />
      </header>
    );
  }
);

MainHeader.propTypes = propTypes;

export const MainHeaderWithRouter = withRouter(MainHeader);
