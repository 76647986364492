import {
  all,
  call,
  debounce,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ROUTES } from 'const';
import { invoicesSearchInit } from 'modules/invoiceSearch/actions';
import { sharepointInitPage } from 'modules/documentsCabinet/actions';
import { initUploadFilesPage } from 'modules/uploadDocuments/actions';
import { debtorsSearchRequest } from 'modules/Debtors/actions';
import { creditorsSearchRequest } from 'modules/Creditors/actions';
import {
  selectUserAdditionalUsers,
  selectUserCompanyId,
  selectUserCurrentCompanyTransition,
  selectUserPackagesNames,
} from 'modules/loggedUserInfo/selectors';
import { API_KEY_MODAL_TRANSLATIONS, GENERAL_ERRORS } from 'const/translations';
import { openURLInNewTab } from 'helpers/common';
import { getUserPackagesFromModules } from 'modules/loggedUserInfo/helpers';
import {
  clearCart,
  getPackagesCostsRequest,
  putUserSubscribedPackages,
} from 'modules/cart/actions';
import {
  INITIAL_DATASET_LOCAL_STORAGE_KEY,
  USER_SAVED_PACKAGES_LOCAL_STORAGE_KEY,
} from 'const/cartPage';
import { clearCartAndAdditionalUsersLocalStorage } from 'helpers/localStorage';
import { setToStorage } from 'helpers/storage';
import { validateMaxPeriod, mapDashboardData } from 'helpers/loggedUserInfo';
import {
  acceptTermsOfUseService,
  fetchIsanetRedirectUrl,
  getRegisteredCompaniesInfos,
  getCompanyDashboardData,
  getCompaniesData,
  getUserFinancials,
  setCurrentCompanyApiKey,
  setNewPrimaryCompany,
  getCompanyData,
} from '../services';
import {
  ACCEPT_TERMS_OF_USE_REQUEST,
  acceptTermsOfUseSuccess,
  CHANGE_CURRENT_COMPANY,
  FETCH_ISANET_REDIRECT_URL,
  LOGGED_USER_INFO_REQUEST,
  loggedUserInfoError,
  loggedUserInfoSuccess,
  SET_CURRENT_COMPANY_API_KEY_REQUEST,
  setCurrentCompanyApiKeySuccess,
  USER_FINANCIALS_DATA_REQUEST,
  userCompaniesDashboardDataSuccess,
  userFinancialsDataError,
  userFinancialsDataSuccess,
  loggedUserInfoRequest,
  GET_COMPANIES_REQUEST,
  getCompaniesSuccess,
} from '../actions';
import { initHRData } from '../../HRPage/actions';
import { initRewards } from '../../SalaryModule/Rewards/actions';
import { initRewardsTableData } from '../../SalaryModule/RewardsTable/actions';

/**
 * fetch data of the companies current user is able to work for,
 * modules user authorized to work with, and user data.
 */
function* loggedUserInfo({ payload: { currentCompanyId } }) {
  try {
    const [allUserData, companiesData] = yield all([
      call(getRegisteredCompaniesInfos),
      call(getCompaniesData),
    ]);

    yield put(
      getCompaniesSuccess({
        companies: companiesData.data,
      })
    );

    const {
      client: { modules, primaryCompany, ...userData },
    } = allUserData;

    const companyId = currentCompanyId || primaryCompany;

    const { data: companyData } = yield call(getCompanyData, companyId);

    const companyDashboardData = yield call(getCompanyDashboardData);

    const maxPeriod = companyDashboardData?.maxPeriod;
    companyDashboardData.maxPeriod = validateMaxPeriod(maxPeriod);

    const mappedData = mapDashboardData({
      [companyId]: companyDashboardData,
    });
    yield put(userCompaniesDashboardDataSuccess({ data: mappedData }));

    const userSubscribedPackages = getUserPackagesFromModules(modules || []);
    yield put(putUserSubscribedPackages({ packages: userSubscribedPackages }));

    yield put(
      loggedUserInfoSuccess({
        modules,
        primaryCompanyData: companyData,
        userData,
      })
    );
  } catch (error) {
    yield put(loggedUserInfoError({ error }));
  }
}

function* changeCurrentCompanyWorker({ payload: { companyId } }) {
  try {
    yield put(invoicesSearchInit());
    yield put(sharepointInitPage());
    yield put(initUploadFilesPage());
    yield put(initHRData());
    yield put(initRewards());
    yield put(initRewardsTableData());

    // clear cart (also clear additional users and cart local storage)
    const additionalUsers = yield select(selectUserAdditionalUsers);
    const userCurrentPackages = yield select(selectUserPackagesNames);
    setToStorage({
      key: INITIAL_DATASET_LOCAL_STORAGE_KEY,
      value: additionalUsers,
    });
    setToStorage({
      key: USER_SAVED_PACKAGES_LOCAL_STORAGE_KEY,
      value: userCurrentPackages,
    });
    clearCartAndAdditionalUsersLocalStorage();
    yield put(clearCart());

    const companiesData = yield call(getCompaniesData);

    yield put(
      getCompaniesSuccess({
        companies: companiesData.data,
      })
    );

    if (window.location.href.includes(`${ROUTES.DEBTOR.split('/')[1]}/`)) {
      yield put(push(ROUTES.DEBTORS));
    }
    if (window.location.href.includes(ROUTES.DEBTORS.split('/')[1])) {
      yield put(debtorsSearchRequest());
    }
    if (window.location.href.includes(ROUTES.CREDITORS.split('/')[1])) {
      yield put(creditorsSearchRequest());
    }
    yield call(setNewPrimaryCompany, companyId);

    yield put(getPackagesCostsRequest());
    yield put(loggedUserInfoRequest({ currentCompanyId: companyId }));
  } catch (error) {
    console.warn(error);
  }
}

function* setCurrentCompanyApiKeyWorker({ payload: { apiKey, onError } }) {
  try {
    const companyId = yield select(selectUserCompanyId);
    const { data } = yield call(setCurrentCompanyApiKey, { companyId, apiKey });
    yield put(setCurrentCompanyApiKeySuccess({ data }));
  } catch (error) {
    console.warn(error);
    onError(API_KEY_MODAL_TRANSLATIONS.ERROR_NOT_VALID);
  }
}

function* isanetSsoRedirectWorker({ payload: { onError } }) {
  try {
    const { url } = yield call(fetchIsanetRedirectUrl);
    yield openURLInNewTab(url);
  } catch (error) {
    if (error.statusCode === 404) {
      onError(GENERAL_ERRORS.SSO_TOKEN_NOT_FOUND);
    }
    console.warn(error);
  }
}

function* userFinancialsWorker({ payload: { companyId, exercice } }) {
  try {
    if (exercice) {
      const transition = yield select(selectUserCurrentCompanyTransition);
      const financials = yield call(
        getUserFinancials,
        exercice,
        transition,
        companyId
      );
      yield put(userFinancialsDataSuccess({ data: financials }));
    } else {
      throw Error('exercice not found');
    }
  } catch (e) {
    yield put(userFinancialsDataError());
  }
}

function* acceptTermsOfUse() {
  try {
    yield call(acceptTermsOfUseService);
    yield put(acceptTermsOfUseSuccess());
  } catch (error) {
    console.warn(error);
  }
}

function* getCompaniesWorker({ payload: { search } }) {
  try {
    const companiesData = yield call(getCompaniesData, search);

    yield put(
      getCompaniesSuccess({
        companies: companiesData.data,
      })
    );
  } catch (error) {
    console.warn(error);
  }
}

function* debounceGetCompaniesWorker() {
  yield debounce(400, GET_COMPANIES_REQUEST, getCompaniesWorker);
}

export function* loggedUserMainSaga() {
  yield all([
    takeLatest(LOGGED_USER_INFO_REQUEST, loggedUserInfo),
    takeLatest(CHANGE_CURRENT_COMPANY, changeCurrentCompanyWorker),
    takeLatest(
      SET_CURRENT_COMPANY_API_KEY_REQUEST,
      setCurrentCompanyApiKeyWorker
    ),
    takeLatest(FETCH_ISANET_REDIRECT_URL, isanetSsoRedirectWorker),
    takeLatest(USER_FINANCIALS_DATA_REQUEST, userFinancialsWorker),
    takeLatest(ACCEPT_TERMS_OF_USE_REQUEST, acceptTermsOfUse),
    debounceGetCompaniesWorker(),
  ]);
}
