import { Modal, message } from 'antd';
import {
  INVOICE_SEARCH_TRANSLATIONS,
  PAID_INVOICE_STATUSES,
} from 'const/translations/InvoiceSearch';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserEmail } from 'modules/loggedUserInfo/selectors';
import {
  invoicesDevalidateRequest,
  invoicesAcceptRequest,
  invoicesDeleteRequest,
  invoicesPrevalidateRequest,
  invoicesPaidOutsideRequest,
  invoicesDevalidateAndApproveRequest,
  cancelInvoicePrevalidationRequest,
  getPayInvoiceUrlRequest,
} from 'modules/invoiceSearch/actions/';
import { showActionConfirmationModal } from 'components/InvoiceSearch/modals/InfoModal/showActionConfirmationModal';

const {
  MODAL: { DEVALIDATE_CONFIRM, DELETE_CONFIRM },
  BUTTONS: {
    PAY_VIA_LIBEO,
    CANCEL,
    DELETE,
    OK,
    DISAPPROVE,
    ACCEPT,
    APPROVE,
    PREVALIDATE,
    PAID_OUTSIDE,
    RESET_VALIDATION,
  },
} = INVOICE_SEARCH_TRANSLATIONS;

const {
  ACCEPTED,
  IN_PROGRESS,
  REJECTED,
  READY_FOR_PAYMENT,
} = PAID_INVOICE_STATUSES;

export const useInvoiceModalButtons = ({
  documentId,
  canPrevalidate,
  paidOutside,
  preValidated,
  handleClose,
  paymentStatus,
  isValidateAction,
  paymentApprovedBy,
  approvedDueDate,
  paymentApprovedComment,
  isValidated,
  isCancellation,
  isPayableInvoice,
  paymentDoneDate,
  canValidate,
  canOnlyView,
  preValidatedBy,
  // isUserHasSearchModule, // 1634
}) => {
  const dispatch = useDispatch();
  const currentUserEmail = useSelector(selectUserEmail);

  const canDevalidate =
    !paidOutside &&
    isPayableInvoice &&
    (canValidate || paymentApprovedBy === currentUserEmail) &&
    isValidated &&
    !paymentDoneDate;

  const infoMode =
    canOnlyView ||
    !isValidated ||
    Boolean(isCancellation) ||
    (!canDevalidate && (canPrevalidate || canValidate));
  const isInfoModal =
    infoMode || [ACCEPTED.value, IN_PROGRESS.value].includes(paymentStatus);

  const isMatchingStatusForDeletion = ![
    ACCEPTED.value,
    IN_PROGRESS.value,
    REJECTED.value,
    READY_FOR_PAYMENT.value,
  ].includes(paymentStatus);
  const isInvoiceDeletable =
    !isValidateAction && (isMatchingStatusForDeletion || !paymentApprovedBy);

  const handlePay = () => {
    dispatch(
      getPayInvoiceUrlRequest({
        invoiceId: documentId,
        onError: (error) => message.error(error),
      })
    );
    handleClose();
  };

  const handleAccept = () => {
    dispatch(invoicesAcceptRequest({ documentId }));
    handleClose();
  };

  const handleDevalidateAndApprove = () => {
    dispatch(
      invoicesDevalidateAndApproveRequest({
        approvedDueDate,
        paymentApprovedComment,
        documentId,
      })
    );
    handleClose();
  };

  const handleDevalidate = () =>
    showActionConfirmationModal({
      handleOk: () => {
        dispatch(invoicesDevalidateRequest({ documentId }));
        Modal.destroyAll();
        handleClose();
      },
      confirmationText: DEVALIDATE_CONFIRM,
    });

  const handlePrevalidate = () => {
    dispatch(invoicesPrevalidateRequest({ documentId }));
    handleClose();
  };

  const handleMarkAsPaidOutside = () => {
    dispatch(invoicesPaidOutsideRequest({ documentId }));
    handleClose();
  };

  const handleDelete = () =>
    showActionConfirmationModal({
      handleOk: () => {
        dispatch(invoicesDeleteRequest({ documentId }));
        Modal.destroyAll();
        handleClose();
      },
      confirmationText: DELETE_CONFIRM,
    });

  const handleDeprevalidate = () => {
    dispatch(cancelInvoicePrevalidationRequest({ documentId }));
    handleClose();
  };

  const devalidationButtons = canDevalidate && [
    { label: DISAPPROVE, onClick: handleDevalidate },
    { label: OK, onClick: handleClose },
  ];

  const prevalidationButtons = !paymentApprovedBy &&
    canPrevalidate &&
    !preValidated && [
      { label: PAID_OUTSIDE, onClick: handleMarkAsPaidOutside },
      { label: PREVALIDATE, onClick: handlePrevalidate },
      { label: CANCEL, onClick: handleClose },
    ];

  const paidOutsideButtons = (canValidate || canPrevalidate) &&
    paidOutside &&
    ((canPrevalidate && paymentApprovedBy === currentUserEmail) ||
      !canPrevalidate) && [
      { label: RESET_VALIDATION, onClick: handleDevalidate },

      !canPrevalidate && {
        label: !canPrevalidate && APPROVE,
        onClick: handleDevalidateAndApprove,
      },
      { label: OK, onClick: handleClose },
    ];

  const infoButtons = isInfoModal && [
    {
      label:
        canPrevalidate &&
        preValidated &&
        preValidatedBy === currentUserEmail &&
        RESET_VALIDATION,
      onClick: handleDeprevalidate,
    },
    {
      label: !canPrevalidate && isInvoiceDeletable && DELETE,
      onClick: handleDelete,
    },
    { label: !canDevalidate ? OK : CANCEL, onClick: handleClose },
  ];

  const payingButtons = [
    { label: PAY_VIA_LIBEO, onClick: handlePay },
    { label: ACCEPT, onClick: handleAccept },
    { label: isInvoiceDeletable && DELETE, onClick: handleDelete },
    { label: CANCEL, onClick: handleClose },
  ];

  return (
    devalidationButtons ||
    prevalidationButtons ||
    paidOutsideButtons ||
    infoButtons ||
    payingButtons
  );
};
