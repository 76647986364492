import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, message, Spin } from 'antd';
import { DOCUMENTS_CABINET_TRANSLATIONS } from 'const/translations';
import { useSimpleMedia } from 'hooks/useMedia';
import { downloadSharepointFileRequest } from 'modules/documentsCabinet/actions';
import { getFileIconByExtension } from 'helpers/documentsCabinet';
import { selectDownloadingDocumentsList } from 'modules/documentsCabinet/selectors';
import Styles from 'components/DocumentsCabinet/DocumentsList/DocumentsList.module.css';

const { ERRORS } = DOCUMENTS_CABINET_TRANSLATIONS;

export const DOCUMENTS_CABINET_SCREEN_WIDTH_BREAKPOINT_VALUE = 475;
export const DOCUMENTS_CABINET_SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${DOCUMENTS_CABINET_SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

const propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  extension: PropTypes.string,
  fileName: PropTypes.string,
  fileId: PropTypes.string,
};

const DataNodeComponent = withRouter(
  ({ name, size, extension, location, history, fileId }) => {
    const dispatch = useDispatch();
    const isMobileVersion = useSimpleMedia(
      DOCUMENTS_CABINET_SCREEN_WIDTH_BREAKPOINT_MEDIA
    );
    const handleGetSubfolder = (folderName) => () => {
      history.push(`${location.pathname}/${folderName}`);
    };
    const isLoading = useSelector(selectDownloadingDocumentsList).includes(
      fileId
    );

    const handleFileDownload = () => {
      if (!isMobileVersion) {
        return;
      }

      dispatch(
        downloadSharepointFileRequest({
          fileId,
          fileName: name,
          onError: () => message.error(ERRORS.DOWNLOAD_FAILED),
        })
      );
    };

    if (size) {
      // File
      return (
        <div className={Styles.tableRow} onClick={handleFileDownload}>
          {isLoading ? (
            <Spin />
          ) : (
            getFileIconByExtension(extension.toLowerCase())
          )}
          <span className={Styles.fileName}>{name}</span>
        </div>
      );
    }

    return (
      // Folder
      <div className={Styles.tableRow}>
        <Icon type="folder" className={Styles.Icon} />
        <span onClick={handleGetSubfolder(name)} className={Styles.folderName}>
          {name}
        </span>
      </div>
    );
  }
);

DataNodeComponent.propTypes = propTypes;

export const DataNode = memo(DataNodeComponent);
