import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';
import { selectInvoiceBeingApprovedId } from 'modules/invoiceSearch/selectors';
import { TableAction } from 'components/ui/TableAction';
import { useInvoiceActionsData } from 'hooks/invoiceSearchActions/useInvoiceActionsData';
import {
  useValidateActionModal,
  useValidateIconClassName,
  useValidateTooltipProps,
} from 'hooks/invoiceSearchActions/validate';

export const ValidateInvoiceAction = () => {
  const {
    documentId,
    isValidated,
    canPrevalidate,
    canOnlyView,
    canValidate,
    // isUserHasSearchModule,
  } = useInvoiceActionsData();

  const invoiceBeingApprovedId = useSelector(selectInvoiceBeingApprovedId);
  const isLoading = Boolean(
    invoiceBeingApprovedId &&
      (invoiceBeingApprovedId === documentId ||
        invoiceBeingApprovedId.includes(documentId))
  );

  const tooltipProps = useValidateTooltipProps();
  const iconStyle = useValidateIconClassName();
  const handleShowModal = useValidateActionModal();

  if (
    !canOnlyView &&
    !canValidate &&
    !canPrevalidate
    // !isUserHasSearchModule  task 1634
  ) {
    return null;
  }

  return (
    <TableAction
      tooltipProps={tooltipProps}
      isLoading={isLoading}
      displayComponent={
        <Icon
          type={isValidated ? 'file-done' : 'exception'}
          className={iconStyle}
          onClick={handleShowModal}
        />
      }
    />
  );
};
