import React, { useCallback, useEffect, useRef } from 'react';
import { withRouter, Route, Link } from 'react-router-dom';
import { Input, Layout, message } from 'antd';
import _ from 'lodash';
import { FoldersMenu } from 'components/DocumentsCabinet/FoldersMenu';
import { DocumentsList } from 'components/DocumentsCabinet/DocumentsList';
import { DOCUMENTS_CABINET_TRANSLATIONS } from 'const/translations';
import {
  selectCurrentPath,
  selectCurrentSearch,
  selectDocumentsCabinetStatus,
  selectDocumentsErrorMessage,
  selectDocumentsTableData,
} from 'modules/documentsCabinet/selectors';
import {
  sharepointFolderRequest,
  sharepointResetStatus,
  sharepointSearchRequest,
} from 'modules/documentsCabinet/actions';
import {
  STATUS_ERROR,
  SHAREPOINT_FORBIDDEN_ERROR_MESSAGE,
  ROUTES,
} from 'const';
import { useSimpleMedia } from 'hooks/useMedia';
import { SectionTitle } from 'components/Section/SectionTitle';
import flowersImg from 'assets/images/loginNewBg.svg';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './DocumentsCabinet.module.css';

const {
  TITLE,
  ERRORS,
  MENU_ITEMS,
  INFO_TEXT: { INFO_TITLE, TEXT1, TEXT2, LINK },
} = DOCUMENTS_CABINET_TRANSLATIONS;

const { Search } = Input;

const DOCUMENTS_CABINET_SCREEN_WIDTH_BREAKPOINT_VALUE = 475;
const DOCUMENTS_CABINET_SCREEN_WIDTH_BREAKPOINT_MEDIA = `(max-width: ${DOCUMENTS_CABINET_SCREEN_WIDTH_BREAKPOINT_VALUE}px)`;

export const DocumentsCabinetPage = withRouter(
  ({ location, match, history }) => {
    const contentRef = useRef(null);
    const dispatch = useDispatch();

    const currentPath = useSelector(selectCurrentPath);
    const currentSearch = useSelector(selectCurrentSearch);
    const tableData = useSelector(selectDocumentsTableData);
    const cabinetStatus = useSelector(selectDocumentsCabinetStatus);
    const cabinetError = useSelector(selectDocumentsErrorMessage);

    const isMobileVersion = useSimpleMedia(
      DOCUMENTS_CABINET_SCREEN_WIDTH_BREAKPOINT_MEDIA
    );

    useEffect(() => {
      if (cabinetStatus === STATUS_ERROR) {
        message.error(
          cabinetError === SHAREPOINT_FORBIDDEN_ERROR_MESSAGE
            ? ERRORS.TRY_AGAIN_LATER
            : ERRORS.FETCH_FOLDER_FAILED
        );
        dispatch(sharepointResetStatus());
      }
    }, [dispatch, cabinetStatus, cabinetError]);

    const goToParent = useCallback(
      (parentIndex) => {
        history.push(
          `${match.path}/${(parentIndex === currentPath.length - 1
            ? currentPath
            : currentPath.slice(0, parentIndex + 1)
          ).join('/')}`
        );
      },
      [currentPath, history, match.path]
    );
    const searchInSharepoint = useCallback(
      (search) => history.push(`${match.path}/?search=${search}`),
      [history, match.path]
    );

    const routes = _.map(MENU_ITEMS, ({ value }) => ({ value })).concat(
      tableData.filter(({ size }) => !size).map(({ name }) => ({ value: name }))
    );

    useEffect(() => {
      const path = location.pathname.split('/').slice(2);
      const search = location.search.split('=').at(1);

      if (!path.length && !search?.length) {
        return;
      }

      if (path.length && path[0] !== '') {
        dispatch(
          sharepointFolderRequest({
            path,
          })
        );
      }

      if (search?.length) {
        dispatch(
          sharepointSearchRequest({
            search,
          })
        );
      }

      // eslint-disable-next-line consistent-return
      return () => {
        dispatch(
          sharepointFolderRequest({
            path: [],
          })
        );
      };
    }, [dispatch, location.pathname, location.search]);

    useEffect(() => {
      if (isMobileVersion && (currentPath.length || currentSearch.length)) {
        window.scrollTo({
          top: 138,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, [currentPath, currentSearch, isMobileVersion]);

    return (
      <div className={Styles.background}>
        <img src={flowersImg} alt="flowers" className={Styles.flowersImg} />
        <div className={Styles.documentsCabinetPage}>
          <SectionTitle
            title={TITLE}
            titleTextColor="white"
            className={Styles.title}
          />
          <Layout className={Styles.contentWrapper}>
            <div className={Styles.sidebarWrapper}>
              <div className={Styles.sidebarSearchRow}>
                <Search
                  defaultValue={currentSearch}
                  onSearch={searchInSharepoint}
                  className={Styles.sidebarSearchInput}
                  allowClear
                  enterButton
                />
              </div>
              <FoldersMenu selected={currentPath[0]} />
            </div>

            {!currentPath.length && !currentSearch.length && (
              <div className={Styles.documentsTextWrapper}>
                <div className={Styles.documentsTextTitle}>{INFO_TITLE}</div>
                <div className={Styles.documentsText}>{TEXT1}</div>
                <div>
                  {TEXT2}&nbsp;&quot;
                  <Link to={ROUTES.UPLOAD_DOCUMENTS}>{LINK}</Link>
                  &quot;.
                </div>
              </div>
            )}
            <React.Fragment ref={contentRef}>
              {routes.map(({ value }) => (
                <Route path={`${match.path}/${value}`}>
                  {Boolean(currentPath.length) && (
                    <Layout.Content className={Styles.content}>
                      <DocumentsList
                        documents={tableData}
                        goToParent={goToParent}
                        cabinetStatus={cabinetStatus}
                      />
                    </Layout.Content>
                  )}
                </Route>
              ))}

              <Route path={`${match.path}`}>
                {Boolean(currentSearch.length) && (
                  <Layout.Content className={Styles.content}>
                    <DocumentsList
                      documents={tableData}
                      goToParent={goToParent}
                      cabinetStatus={cabinetStatus}
                    />
                  </Layout.Content>
                )}
              </Route>
            </React.Fragment>
          </Layout>
        </div>
      </div>
    );
  }
);
