import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form, Col, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { Radio } from 'components/ui';
import MainStyles from 'assets/styles/main.module.css';
import {
  selectCanValidate,
  selectCanPay,
  selectCanPrevalidate,
  // selectUserAuthorizedModules,
} from 'modules/loggedUserInfo/selectors';
import { INVOICE_SEARCH_TRANSLATIONS } from 'const/translations';
import Styles from 'containers/InvoiceSearchPage/InvoiceSearch.module.css';

const { labels, lists } = INVOICE_SEARCH_TRANSLATIONS;

const propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export const StatusInput = ({ onChange, value }) => {
  const canPay = useSelector(selectCanPay);
  const canValidate = useSelector(selectCanValidate);
  const canPrevalidate = useSelector(selectCanPrevalidate);
  // const userModules = useSelector(selectUserAuthorizedModules);

  // const isUserHasSearchModule = userModules.includes('SEARCH_INVOICES');  task 1634
  const paymentStatusOptions =
    (canPay && lists.paidStatuses) ||
    (canValidate && lists.paidStatusesForValidatingUser) ||
    (canPrevalidate && lists.paidStatusesForPreValidatingUser) ||
    [];

  if (
    !canPay &&
    !canValidate &&
    !canPrevalidate
    // && !isUserHasSearchModule  task 1634
  ) {
    return null;
  }

  return (
    <Col span={24} className={MainStyles.col}>
      <Typography.Paragraph
        className={clsx(MainStyles.formLabel, Styles.formLabel)}
      >
        {labels.status}
      </Typography.Paragraph>
      <Form.Item className={Styles.formItem}>
        <Radio
          options={paymentStatusOptions}
          onChange={onChange}
          value={value}
          name="paymentStatus"
        />
      </Form.Item>
    </Col>
  );
};

StatusInput.propTypes = propTypes;
