import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon, Menu } from 'antd';
import Styles from './FoldersMenuItem.module.css';

const propTypes = {
  isSelected: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  name: PropTypes.string,
};

export const FoldersMenuItem = withRouter(
  ({ isSelected, children, name, history, match, ...props }) => {
    const onChangeFolder = useCallback(() => {
      const newPath = `${match.path}/${name}`;

      if (history.location.pathname === newPath) {
        history.push(match.path);

        return;
      }

      history.push(newPath);
    }, [history, match.path, name]);

    return (
      <Menu.Item {...props} onClick={onChangeFolder}>
        {isSelected ? (
          <Icon type="folder-open" className={Styles.Icon} />
        ) : (
          <Icon type="folder" className={Styles.Icon} />
        )}
        {children}
      </Menu.Item>
    );
  }
);

FoldersMenuItem.propTypes = propTypes;
