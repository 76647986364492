import moment from 'moment';

export const mapDashboardData = (data) =>
  Object.entries(data).map(([key, values]) => ({
    dosId: key,
    data: values,
  }));

/**
 * Validates and returns the maximum period.
 *
 * @param {string} maxPeriod - The maximum period to be validated.
 * @returns {string} The maximum period if it's valid, or the calculated period otherwise.
 */
export const validateMaxPeriod = (maxPeriod) => {
  let currentMonth = moment().month() + 1;
  let currentYear = moment().year();
  const currentDay = moment().date();

  if (currentDay < 25) {
    currentYear = currentMonth < 3 ? currentYear - 1 : currentYear;
    currentMonth = currentMonth < 3 ? 13 - currentMonth : currentMonth - 2;
  } else {
    currentYear = currentMonth === 1 ? currentYear - 1 : currentYear;
    currentMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  }

  const calculatedMaxPeriod = `${currentYear}${String(currentMonth).padStart(
    2,
    '0'
  )}`;

  return maxPeriod === calculatedMaxPeriod ? maxPeriod : calculatedMaxPeriod;
};
