import { useCallback } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import Styles from 'assets/styles/main.module.css';

import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';
import { selectUserCurrentCompanyPreCompta } from 'modules/loggedUserInfo/selectors';
import { isFileNameRepeat, validateFile } from 'helpers/common';
import { MAX_FILES_SIZE_25MO, MAX_FILES_SIZE_10MO } from 'const';
import { openSuccessNotification } from 'helpers/notifications';

const {
  MESSAGES: { REMOVED },
  MODAL: {
    TITLE,
    TEXT,
    TITLE_REMOVE_ALL,
    TEXT_REMOVE_ALL,
    CANCEL_BUTTON_TEXT,
    APPLY_BUTTON_TEXT,
  },
} = UPLOAD_DOCUMENT_TRANSLATIONS;

const repeatedFileName = (file, fileList, count = 1) => {
  if (!file) {
    return null;
  }

  const isFileNameRepeated = isFileNameRepeat(file, fileList);

  if (isFileNameRepeated) {
    const newCount = count + 1;
    const name = (file.originalName || file.name)
      .split('.')
      .slice(0, -1)
      .join('.');
    const extension = (file.originalName || file.name).split('.').at(-1);

    const newFile = {
      ...file,
      name: `${name} (${newCount}).${extension}`,
      originalName: file.originalName || file.name,
    };
    return repeatedFileName(newFile, fileList, newCount);
  }

  return file.name;
};

export const useUpload = (files, _setFiles, isRenameable) => {
  const isPreCompta = useSelector(selectUserCurrentCompanyPreCompta);
  const setFiles = useCallback(
    (fileList, _file) => {
      let freeSize =
        (isPreCompta && MAX_FILES_SIZE_25MO) || MAX_FILES_SIZE_10MO;

      const extendedFileList = fileList.map((file, index) => {
        freeSize -= file.size;
        const extension = file.name.split('.').at(-1);
        const newFileName = repeatedFileName(_file, fileList);
        const fileErrors = validateFile({
          file,
          isPreCompta,
          index,
          freeSize,
          extension,
          isRenameable,
        });

        return {
          ...file,
          name: file.uid === _file?.uid ? newFileName : file.name,
          originalName: file.name,
          errors: fileErrors,
        };
      });
      _setFiles(extendedFileList);
    },
    [_setFiles, isPreCompta, isRenameable]
  );

  const handleChange = useCallback(
    ({ fileList, file }) => setFiles(fileList, file),
    [setFiles]
  );

  const handleRemove = useCallback(
    (uid, name) => {
      Modal.confirm({
        title: TITLE,
        icon: false,
        content: TEXT.replace('{{FILE_NAME}}', name),
        className: Styles.modal,
        okText: APPLY_BUTTON_TEXT,
        okButtonProps: {
          className: Styles.modalOkButton,
        },
        cancelText: CANCEL_BUTTON_TEXT,
        cancelButtonProps: {
          className: Styles.modalCancelButton,
        },
        autoFocusButton: null,
        onOk() {
          const newFiles = files.filter((file) => file.uid !== uid);
          setFiles(newFiles);
          openSuccessNotification({
            message: REMOVED.replace('{{FILE_NAME}}', name),
          });
        },
        onCancel() {},
      });
    },
    [files, setFiles]
  );

  const handleReset = useCallback(() => {
    Modal.confirm({
      title: TITLE_REMOVE_ALL,
      icon: false,
      content: TEXT_REMOVE_ALL,
      className: Styles.modal,
      okText: APPLY_BUTTON_TEXT,
      okButtonProps: {
        className: Styles.modalOkButton,
      },
      cancelText: CANCEL_BUTTON_TEXT,
      cancelButtonProps: {
        className: Styles.modalCancelButton,
      },
      onOk() {
        setFiles([]);
      },
      onCancel() {},
    });
  }, [setFiles]);

  return { handleChange, handleRemove, handleReset };
};
