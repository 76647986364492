import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const usePathOverlapWith = (paths, isDirectComparison = false) => {
  const { pathname } = useLocation();
  return useMemo(
    () =>
      paths.some((route) =>
        isDirectComparison ? pathname === route : pathname.includes(route)
      ),
    [isDirectComparison, pathname, paths]
  );
};
