import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Icon, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSimpleMedia } from 'hooks/useMedia';
import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'const/translations';
import { selectUserCurrentCompanyPreCompta } from 'modules/loggedUserInfo/selectors';
import {
  EMPTY_FUNCTION,
  UPLOAD_DOCUMENTS_FILE_ERRORS,
  SCREEN_WIDTH_BREAKPOINT_MEDIA,
} from 'const';
import {
  isFileNameIncorrect,
  isFileRequired,
  getFileNameWithoutExtension,
} from 'helpers/common';
import Styles from './File.module.css';

const {
  FILES_LIST: {
    TOOLTIP_TEXT: {
      COUNT_LIMIT_ERROR_MESSAGE_10,
      COUNT_LIMIT_ERROR_MESSAGE_20,
      SIZE_LIMIT_ERROR_MESSAGE_10MO,
      SIZE_LIMIT_ERROR_MESSAGE_25MO,
      TYPE_FORBIDDEN_ERROR_MESSAGE,
      FILE_PATTERN_ERROR_MESSAGE,
      FILE_NAME_REQUIRED_ERROR_MESSAGE,
      FILE_NAME_REPEAT,
    },
  },
} = UPLOAD_DOCUMENT_TRANSLATIONS;
const {
  COUNT_LIMIT_ERROR,
  SIZE_LIMIT_ERROR,
  TYPE_FORBIDDEN_ERROR,
  FILE_PATTERN_ERROR,
  FILE_NAME_REPEATS,
  FILE_NAME_REQUIRED,
} = UPLOAD_DOCUMENTS_FILE_ERRORS;

const propTypes = {
  uid: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.shape({
    [COUNT_LIMIT_ERROR]: PropTypes.bool,
    [SIZE_LIMIT_ERROR]: PropTypes.bool,
    [TYPE_FORBIDDEN_ERROR]: PropTypes.bool,
    [FILE_PATTERN_ERROR]: PropTypes.bool,
    [FILE_NAME_REPEATS]: PropTypes.number,
    [FILE_NAME_REQUIRED]: PropTypes.bool,
  }),
  onRemove: PropTypes.func,
  className: PropTypes.string,
  removeIcon: PropTypes.node,
  file: PropTypes.any,
  files: PropTypes.any,
  onFileDownload: PropTypes.func,
  hasDownload: PropTypes.bool,
  isRenameable: PropTypes.bool,
  handleChange: PropTypes.func,
  setIsOneFileEditing: PropTypes.func,
};

const getFileErrorMessages = ({
  errors,
  isPreCompta,
  isNewFileNameIncorrect,
  isNewFileNameEmpty,
}) => {
  const errorMessagesMap = {
    [COUNT_LIMIT_ERROR]: isPreCompta
      ? COUNT_LIMIT_ERROR_MESSAGE_20
      : COUNT_LIMIT_ERROR_MESSAGE_10,

    [SIZE_LIMIT_ERROR]: isPreCompta
      ? SIZE_LIMIT_ERROR_MESSAGE_25MO
      : SIZE_LIMIT_ERROR_MESSAGE_10MO,

    [TYPE_FORBIDDEN_ERROR]: TYPE_FORBIDDEN_ERROR_MESSAGE,
    [FILE_PATTERN_ERROR]: FILE_PATTERN_ERROR_MESSAGE,
    [FILE_NAME_REQUIRED]: FILE_NAME_REQUIRED_ERROR_MESSAGE,
  };

  const messages = Object.keys(errorMessagesMap)
    .filter((errorKey) => errors[errorKey])
    .map((errorKey) => errorMessagesMap[errorKey]);

  if (isNewFileNameIncorrect || errors[FILE_PATTERN_ERROR]) {
    messages.push(FILE_PATTERN_ERROR_MESSAGE);
  }

  if (errors[FILE_NAME_REPEATS]) {
    messages.push(
      FILE_NAME_REPEAT.replace('{{AMOUNT}}', errors[FILE_NAME_REPEATS])
    );
  }

  if (isNewFileNameEmpty || errors[FILE_NAME_REQUIRED]) {
    messages.push(FILE_NAME_REQUIRED_ERROR_MESSAGE);
  }

  return messages;
};

export const File = ({
  className,
  removeIcon,
  uid,
  name = '',
  onRemove,
  onFileDownload,
  errors = [],
  file = null,
  files,
  hasDownload = false,
  isRenameable,
  handleChange,
  setIsOneFileEditing,
}) => {
  const inputRef = useRef(null);
  const isMobileVersion = useSimpleMedia(SCREEN_WIDTH_BREAKPOINT_MEDIA);
  const isPreCompta = useSelector(selectUserCurrentCompanyPreCompta);
  const [isEdit, setIsEdit] = useState(false);

  const fileNameWithoutExtension = getFileNameWithoutExtension(name);
  const [newFileName, setNewFileName] = useState(fileNameWithoutExtension);
  const [isNewFileNameIncorrect, setIsNewFileNameIncorrect] = useState(false);
  const [isNewFileNameEmpty, setIsNewFileNameEmpty] = useState(false);

  const onEditClick = () => {
    setIsEdit(true);
    setIsOneFileEditing(true);

    setTimeout(() => {
      inputRef.current.focus();
    }, 50);
  };

  const onInputChange = ({ target: { value } }) => {
    setNewFileName(value);
    setIsNewFileNameIncorrect(isFileNameIncorrect(value));
    setIsNewFileNameEmpty(isFileRequired(value.trim()));
  };

  const onSave = () => {
    if (!newFileName.trim() || isNewFileNameIncorrect) {
      return;
    }

    const newFiles = [...files];
    const currentFile = newFiles.find((el) => el.uid === uid);
    const lastExtension = currentFile.extension;
    const extension = file.name.split('.').at(-1);

    currentFile.name = [newFileName.trim(), extension].join('.');

    if (!lastExtension) {
      currentFile.extension = extension;
    }

    currentFile.originalName = currentFile.name;

    handleChange({ fileList: newFiles, file: currentFile });
    setNewFileName(newFileName.trim());
    setIsEdit(false);
    setIsOneFileEditing(false);
  };

  const onCancelClick = () => {
    setIsEdit(false);
    setIsOneFileEditing(false);
    setIsNewFileNameIncorrect(false);
    setIsNewFileNameEmpty(false);
    setNewFileName(fileNameWithoutExtension);
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter') {
      onSave();
    }
  };

  const messages = getFileErrorMessages({
    errors,
    isPreCompta,
    isNewFileNameIncorrect,
    isNewFileNameEmpty,
  });

  const tooltipErrorTitle = messages.join('\n\n');

  const removeFile = useCallback(() => {
    if (onRemove) return onRemove(uid, name);
    return EMPTY_FUNCTION();
  }, [uid, name, onRemove]);
  const handleFileDownload = useCallback(() => {
    if (onFileDownload) onFileDownload(file, name);
  }, [file, name, onFileDownload]);

  return (
    <Tooltip
      title={tooltipErrorTitle || null}
      overlayClassName={Styles.tooltip}
      placement="topRight"
      trigger={isMobileVersion ? 'click' : 'hover'}
      visible={isNewFileNameIncorrect || isNewFileNameEmpty}
    >
      <div className={clsx(Styles.fileItem, className)}>
        <Icon
          type="file"
          className={clsx(Styles.Icon, Styles.fileIcon, {
            [Styles.disabled]: tooltipErrorTitle,
          })}
        />
        {!isEdit ? (
          <>
            <span
              title={name}
              className={clsx(Styles.fileName, {
                [Styles.disabled]: tooltipErrorTitle,
                [Styles.download]: hasDownload,
              })}
              onClick={isRenameable ? onEditClick : handleFileDownload}
            >
              {name.length > 70
                ? `${name.substring(0, 52)}...${name.slice(-10)}`
                : name}
            </span>
          </>
        ) : (
          <>
            <input
              ref={inputRef}
              name="fileName"
              value={newFileName}
              className={Styles.input}
              onChange={onInputChange}
              onBlur={onSave}
              onKeyDown={onEnterPress}
              autoComplete="off"
              placeholder="Nom de fichier"
              required
            />
          </>
        )}

        {!isEdit ? (
          <>
            <div>
              <Icon
                type="exclamation-circle"
                style={{
                  color: 'var(--red)',
                  marginLeft: '5px',
                  visibility: tooltipErrorTitle ? 'visible' : 'hidden',
                }}
              />
            </div>

            {isRenameable && (
              <div>
                <EditOutlined
                  className={Styles.editIcon}
                  onClick={onEditClick}
                />
              </div>
            )}
            {onRemove && (
              <div onClick={removeFile}>
                {removeIcon || (
                  <Icon type="delete" className={Styles.removeIcon} />
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <Icon
              type="exclamation-circle"
              style={{
                color: 'var(--red)',
                marginLeft: '5px',
                visibility: tooltipErrorTitle ? 'visible' : 'hidden',
              }}
            />
            <CheckCircleOutlined
              className={clsx(Styles.checkIcon, {
                [Styles.disabled]: tooltipErrorTitle,
              })}
              onClick={onSave}
            />
            <CloseCircleOutlined
              className={Styles.closeIcon}
              onClick={onCancelClick}
              onMouseDown={(e) => e.preventDefault()}
            />
          </>
        )}
      </div>
    </Tooltip>
  );
};

File.propTypes = propTypes;
